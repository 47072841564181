import { Hidden } from '@mui/material';
import React from 'react';

import { Drawer } from './NavSideBar.styles';
import { NavSideBarContent } from '../NavSideBarContent';

type Props = { open: boolean; toggleDrawer: (open: boolean) => void };

export const NavSideBar = ({ open, toggleDrawer }: Props) => (
  <>
    <Hidden smDown>
      <Drawer onClose={() => toggleDrawer(false)} onOpen={() => toggleDrawer(true)} variant="permanent" open={open}>
        <NavSideBarContent open={open} />
      </Drawer>
    </Hidden>
    <Hidden smUp>
      <Drawer
        variant="temporary"
        open={open}
        onClose={() => toggleDrawer(false)}
        onOpen={() => toggleDrawer(true)}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <NavSideBarContent open={open} />
      </Drawer>
    </Hidden>
  </>
);
