import React from 'react';
import { useSelector } from 'react-redux';

import { Loading } from 'common/components/Loading/Loading';
import { selectAuth } from 'modules/auth/redux/authReducer';

import { Container, ContentBox, Main, Root } from './PrivateLayout.styles';
import PrivateLayoutWrapper from './PrivateLayoutWrapper';
import { usePrivateGuard } from '../hooks/usePrivateGuard';

export const siteTitle = 'LM';

const PrivateLayout = React.memo(() => {
  const { user } = useSelector(selectAuth);
  usePrivateGuard();

  if (!user) {
    return (
      <Root>
        <ContentBox>
          <Main>
            <Container>
              <Loading />
            </Container>
          </Main>
        </ContentBox>
      </Root>
    );
  }

  return <PrivateLayoutWrapper />;
});

PrivateLayout.displayName = 'PrivateLayout';

export default PrivateLayout;
