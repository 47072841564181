import { TypographyOptions } from '@mui/material/styles/createTypography';

export function remToPx(value: string) {
  return Math.round(parseFloat(value) * 16);
}

export function pxToRem(value: number) {
  return `${value / 16}rem`;
}
type ResponsiveFontSizesType = { sm: number; md: number; lg: number };

export function responsiveFontSizes({ sm, md, lg }: ResponsiveFontSizesType) {
  return {
    '@media (min-width:600px)': {
      fontSize: pxToRem(sm),
    },
    '@media (min-width:900px)': {
      fontSize: pxToRem(md),
    },
    '@media (min-width:1200px)': {
      fontSize: pxToRem(lg),
    },
  };
}

const FONT_PRIMARY = '"Roboto", "Helvetica", "Arial", sans-serif'; // Google Font

export const typography: TypographyOptions = {
  fontFamily: FONT_PRIMARY,
  fontWeightRegular: 400,
  fontWeightMedium: 600,
  fontWeightBold: 700,
  h1: {
    fontWeight: 500,
    fontSize: 40,
    lineHeight: '48px',
    letterSpacing: '0.2px',
  },
  h2: {
    fontSize: 34,
    fontWeight: 500,
    lineHeight: '40px',
    letterSpacing: '0.1px',
  },
  h3: {
    fontWeight: 500,
    fontSize: 28,
    lineHeight: '36px',
  },
  h4: {
    fontSize: '24px',
    lineHeight: '28px',
    fontWeight: 500,
    letterSpacing: '0.15px',
  },
  h5: {
    fontSize: 20,
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: '0.2px',
  },
  h6: {
    fontSize: 18,
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: '0.15px',
  },
  body1: {
    letterSpacing: '0.5px',
  },
  body2: {
    fontSize: 16,
    lineHeight: '20px',
    letterSpacing: '0.25px',
  },
  subtitle1: {
    lineHeight: '20px',
    letterSpacing: '0.15px',
  },
  subtitle2: {
    lineHeight: '16px',
    letterSpacing: '0.1px',
  },
  caption: {
    lineHeight: '16px',
    letterSpacing: '0.4px',
  },
  button: {
    fontWeight: 400,
    fontSize: '14px',
    letterSpacing: '0.5px',
    textTransform: 'capitalize',
  },
};
