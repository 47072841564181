import { Link } from '@mui/material';
import React from 'react';

import logoImage from '../../../assets/logo.png';
import { ImageCover } from '../ImageCover';

type Props = {
  disabledLink: boolean;
  width?: number;
  height?: string;
};

export const Logo = ({ disabledLink = false, width = 70, height = 'auto' }: Props) => {
  const logo = <ImageCover src={logoImage} width={width} height={height} alt="" />;

  if (disabledLink) {
    return logo;
  }

  return (
    <Link href="/" sx={{ display: 'contents' }}>
      {logo}
    </Link>
  );
};

Logo.displayName = 'Logo';
