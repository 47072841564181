import auth from './auth/auth.json';
import login from './auth/login.json';
import passwordRecovery from './auth/passwordRecovery.json';
import registration from './auth/registration.json';
import common from './common.json';
import errors from './errors.json';
import clients from './private/clients.json';
import companies from './private/companies.json';
import privateHome from './private/home.json';
import privateNavbar from './private/navbar.json';
import proxyHosts from './private/proxyHosts.json';
import proxyPools from './private/proxyPools.json';
import servers from './private/servers.json';

export default {
  common,
  errors,
  auth,
  login,
  servers,
  companies,
  clients,
  registration,
  privateHome,
  proxyHosts,
  proxyPools,
  passwordRecovery,
  privateNavbar,
};
