import { useMediaQuery, useTheme } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectNavSideBarState, setNavSideBarOpen } from '../PrivateLayout/redux/navSideBarReducer';

export const useDrawer = () => {
  const theme = useTheme();
  const mobileQuery = useMediaQuery(theme.breakpoints.down('md'));

  const { open: prev } = useSelector(selectNavSideBarState);
  const dispatch = useDispatch();
  const [mobileOpen, setMobileOpen] = useState(false);

  const toggleDrawerPermanent = useCallback(
    (open?: boolean) => {
      dispatch(setNavSideBarOpen(open ?? !prev));
    },
    [dispatch, prev],
  );

  const toggleDrawerTemporary = useCallback((open?: boolean) => {
    setMobileOpen((prev) => open ?? !prev);
  }, []);

  const toggleDrawer = mobileQuery ? toggleDrawerTemporary : toggleDrawerPermanent;
  const drawerOpen = mobileQuery ? mobileOpen : prev;

  useEffect(() => {
    if (!mobileQuery) {
      toggleDrawerTemporary(false);
    }
  }, [mobileQuery, toggleDrawerTemporary]);

  return { open: drawerOpen, mobileOpen, toggleDrawer };
};
