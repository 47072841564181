import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ruRU } from '@mui/x-date-pickers/locales';
import { QueryClientProvider } from '@tanstack/react-query';
import { ru } from 'date-fns/locale';
import dayjs from 'dayjs';
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { AppRoutes } from 'routes';

import { AppSnackbar } from './common/components/AppSnackbar/AppSnackbar';
import i18n from './common/i18n/i18n';
import { store } from './common/redux/store';
import { ThemeProvider } from './common/theme';
import { queryClient } from './common/utils/queryClient';
import 'dayjs/locale/ru';

dayjs.locale(i18n.language);

const App = () => (
  <LocalizationProvider
    localeText={ruRU.components.MuiLocalizationProvider.defaultProps.localeText}
    dateAdapter={AdapterDayjs}
    adapterLocale={i18n.language}
  >
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <BrowserRouter>
          <ThemeProvider>
            <AppSnackbar>
              <AppRoutes />
            </AppSnackbar>
          </ThemeProvider>
        </BrowserRouter>
      </Provider>
    </QueryClientProvider>
  </LocalizationProvider>
);

export default App;
