import React, { Suspense, lazy } from 'react';
import { Route, Routes } from 'react-router-dom';

import { FlexBox } from 'common/components/FlexBox';
import { Loading } from 'common/components/Loading/Loading';
import PrivateLayout from 'modules/private/layout/PrivateLayout/PrivateLayout';

const AuthLayout = lazy(() => import('modules/auth/layout/AuthLayout'));
const AuthRoutes = lazy(() => import('modules/auth/routes'));
const PrivateRoutes = lazy(() => import('modules/private/routes'));
const HomePage = lazy(() => import('modules/private/home/HomePage'));

export const AppRoutes = () => (
  <Suspense
    fallback={
      <FlexBox height="100vh" alignItems="center" justifyContent="center" flex={1}>
        <Loading />
      </FlexBox>
    }
  >
    <Routes>
      <Route path="/auth" element={<AuthLayout />}>
        <Route path="/auth/*" element={<AuthRoutes />} />
      </Route>

      <Route path="/private" element={<PrivateLayout />}>
        <Route index element={<HomePage />} />
        <Route path="/private/*" element={<PrivateRoutes />} />
      </Route>

      <Route path="/*" element={<>NotFound</>} />
    </Routes>
  </Suspense>
);
