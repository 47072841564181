import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'common/redux/store';
import { queryClient } from 'common/utils/queryClient';
import { SessionCurrentResponseType } from 'modules/private/requests/type';

import { AuthUserResponseType } from '../types';
import { authInitialState as initialState } from './constants/authState';

// Работа с localStorage
const ACCESS_TOKEN_KEY = 'accessToken';
// const REFRESH_TOKEN_KEY = 'refreshToken';

const removeTokens = () => {
  localStorage.removeItem(ACCESS_TOKEN_KEY);
  // localStorage.removeItem(REFRESH_TOKEN_KEY);
};

const saveTokens = (accessToken: string | null) => {
  if (accessToken) {
    localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
  }
  // if (refreshToken) {
  //   localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
  // }
};

export const getAccessToken = () => {
  return localStorage.getItem(ACCESS_TOKEN_KEY);
};

// Создание слайса auth
export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, action: PayloadAction<AuthUserResponseType>) => {
      const { payload } = action;
      saveTokens(payload.accessToken || null);
      state.isAuth = true;
      state.user = payload;
    },
    sessionCurrent: (state, action: PayloadAction<SessionCurrentResponseType>) => {
      const { payload } = action;
      const accessToken = getAccessToken();
      state.isAuth = !!accessToken;
      state.user = { ...payload, accessToken };
    },
    logout: (state) => {
      removeTokens();
      console.log(123);
      queryClient.clear();
      state.isAuth = false;
      state.user = null;
    },
  },
});

// Экспортируем действия для использования в компонентах
export const { login, sessionCurrent, logout } = authSlice.actions;

// Селектор для auth
export const selectAuth = (state: RootState) => state.auth;

export default authSlice.reducer;
