import AltRouteRoundedIcon from '@mui/icons-material/AltRouteRounded';
import ComputerRoundedIcon from '@mui/icons-material/ComputerRounded';
import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded';
import RouterRoundedIcon from '@mui/icons-material/RouterRounded';
import StorageRoundedIcon from '@mui/icons-material/StorageRounded';
import { List } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { MAIN_ROUTES } from 'common/constants/mainRoutes';
import { useCheckSelectedPath } from 'common/hooks/useCheckSelectedPath';

import { SideBarItemList } from '../SideBarItemList/SideBarItemList';

type Props = { open: boolean };

export const NavSideBarContent = ({ open }: Props) => {
  const { t } = useTranslation('privateNavbar');

  const { checkSelectedPath } = useCheckSelectedPath();

  return (
    <List>
      <SideBarItemList
        icon={<ComputerRoundedIcon />}
        open={open}
        path={MAIN_ROUTES.SERVERS}
        text={t('servers')}
        selected={checkSelectedPath(MAIN_ROUTES.SERVERS)}
      />
      <SideBarItemList
        icon={<StorageRoundedIcon />}
        open={open}
        path={MAIN_ROUTES.COMPANIES}
        text={t('companies')}
        selected={checkSelectedPath(MAIN_ROUTES.COMPANIES)}
      />
      <SideBarItemList
        icon={<RouterRoundedIcon />}
        open={open}
        path={MAIN_ROUTES.PROXY_HOSTS}
        text={t('proxyHosts')}
        selected={checkSelectedPath(MAIN_ROUTES.PROXY_HOSTS)}
      />
      <SideBarItemList
        icon={<AltRouteRoundedIcon />}
        open={open}
        path={MAIN_ROUTES.PROXY_POOLS}
        text={t('proxyPools')}
        selected={checkSelectedPath(MAIN_ROUTES.PROXY_POOLS)}
      />
      <SideBarItemList
        icon={<PeopleAltRoundedIcon />}
        open={open}
        path={MAIN_ROUTES.CLIENTS}
        text={t('clients')}
        selected={checkSelectedPath(MAIN_ROUTES.CLIENTS)}
      />
    </List>
  );
};
