import { Badge, Box, useTheme } from '@mui/material';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { countAccumulate } from 'common/utils/countAccumulate';

import {
  SideBarItemListStyled,
  SideBarListItemButton,
  SideBarListItemIcon,
  SideBarListItemText,
} from './SideBarItemList.styles';

type Props = { open: boolean; path: string; icon?: JSX.Element; count?: number; selected: boolean; text: string };

export const SideBarItemList = ({ open, path, icon, text, selected, count }: Props) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const countAccumulateMemoized = useMemo(() => countAccumulate(count || 0), [count]);

  return (
    <Box mb={1}>
      <SideBarItemListStyled disablePadding>
        <SideBarListItemButton onClick={() => navigate(path)} selected={selected} open={open}>
          {icon && (
            <SideBarListItemIcon open={open}>
              {countAccumulateMemoized ? (
                <Badge
                  badgeContent={countAccumulateMemoized}
                  color="error"
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                >
                  {icon}
                </Badge>
              ) : (
                icon
              )}
            </SideBarListItemIcon>
          )}
          <SideBarListItemText primaryTypographyProps={{ ...theme.typography.body2 }} primary={text} open={open} />
        </SideBarListItemButton>
      </SideBarItemListStyled>
    </Box>
  );
};
