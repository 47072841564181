import { ListItem, ListItemButton, ListItemIcon, ListItemText, styled } from '@mui/material';

export const SideBarItemListStyled = styled(ListItem)(({ theme }) => ({
  padding: `0 ${theme.spacing(1.5)}`,
}));

export const SideBarListItemButton = styled(ListItemButton)<{ open?: boolean }>(({ theme }) => ({
  minHeight: 48,
  display: 'flex',
  paddingRight: 0,
  paddingLeft: 0,
  borderRadius: theme.spacing(2),
}));

export const SideBarListItemIcon = styled(ListItemIcon)<{ open?: boolean }>(({ theme, open }) => ({
  minWidth: 0,
  margin: open ? `0px 0px 0px ${theme.spacing(1)}` : 'auto',
  flexBasis: '27px',
}));

export const SideBarListItemText = styled(ListItemText)<{ open?: boolean }>(({ theme, open }) => ({
  marginLeft: open ? theme.spacing(1) : theme.spacing(0),
  display: open ? 'block' : 'none',
  opacity: open ? 1 : 0,
}));
