import { useEffect } from 'react';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';

import { MAIN_ROUTES } from 'common/constants/mainRoutes';
import { useAppSelector } from 'common/redux/store';
import { selectAuth } from 'modules/auth/redux/authReducer';
import { useSessionQuery } from 'modules/private/hooks/useSessionQuery';

export const usePrivateGuard = () => {
  const { data, isLoading } = useSessionQuery();
  const { user, isAuth } = useAppSelector(selectAuth);
  const location = useLocation();

  const navigate = useNavigate();

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (!data) {
      navigate({ pathname: MAIN_ROUTES.LOGIN, search: createSearchParams({ fromUrl: location.pathname }).toString() });
    }
  }, [data, isAuth, user, isLoading, navigate, location.pathname]);
};
