import { styled, AppBarProps, Toolbar } from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';

export const navbarHeight = 64;
export const navbarHeightSm = 64;

export const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme }) => ({
  backdropFilter: 'blur(6px)',
  zIndex: theme.zIndex.drawer + 1,
  background: theme.palette.background.paper,

  [theme.breakpoints.down('md')]: {
    backdropFilter: 'blur(1px)',
    background: 'rgba(255,255,255,1)',
  },
}));

export const AppToolbar = styled(Toolbar)<AppBarProps>(({ theme }) => ({
  borderBottom: 0,
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',

  height: navbarHeight,

  [theme.breakpoints.down('md')]: {
    height: navbarHeightSm,
  },
}));
