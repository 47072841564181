export const SUB_PATH = {
  AUTH: '/auth',
  PRIVATE: '/private',
  SERVERS: '/servers',
  COMPANIES: '/companies',
  PROXY_POOLS: '/proxy-pools',
  PROXY_HOSTS: '/proxy-hosts',
  CLIENTS: '/clients',
};

export const MAIN_ROUTES = {
  MAIN: '/',
  LOGIN: `${SUB_PATH.AUTH}/login`,
  HOME: `${SUB_PATH.PRIVATE}`,
  SERVERS: `${SUB_PATH.PRIVATE}${SUB_PATH.SERVERS}`,
  COMPANIES: `${SUB_PATH.PRIVATE}${SUB_PATH.COMPANIES}`,
  PROXY_POOLS: `${SUB_PATH.PRIVATE}${SUB_PATH.PROXY_POOLS}`,
  PROXY_HOSTS: `${SUB_PATH.PRIVATE}${SUB_PATH.PROXY_HOSTS}`,
  CLIENTS: `${SUB_PATH.PRIVATE}${SUB_PATH.CLIENTS}`,
};
