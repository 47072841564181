import React from 'react';
import { Outlet } from 'react-router-dom';

import { Loading } from 'common/components/Loading/Loading';

import { Container, ContentBox, Main, Root } from './PrivateLayout.styles';
import { Navbar } from './components/Navbar';
import { useDrawer } from '../hooks/useDrawer';
import { NavSideBar } from './components/Navbar/components/NavSideBar';

export const siteTitle = 'ProParser';

const PrivateLayoutWrapper = React.memo(() => {
  const { open, toggleDrawer } = useDrawer();

  return (
    <Root>
      <ContentBox>
        <Navbar toggleDrawer={toggleDrawer} />
        <NavSideBar open={open} toggleDrawer={toggleDrawer} />

        <Main>
          <Container>
            <React.Suspense fallback={<Loading />}>
              <Outlet />
            </React.Suspense>
          </Container>
        </Main>
      </ContentBox>
    </Root>
  );
});

PrivateLayoutWrapper.displayName = 'PrivateLayoutWrapper';

export default PrivateLayoutWrapper;
