import axios, { AxiosError } from 'axios';

import { store } from 'common/redux/store';
import { getAccessToken, logout } from 'modules/auth/redux/authReducer';

export const ajax = axios.create({
  baseURL: process.env.REACT_APP_PUBLIC_BACKEND_URL,
  withCredentials: true,
});

ajax.interceptors.request.use(async (req) => {
  const token = getAccessToken();

  if (token) {
    req.headers.Authorization = `Bearer ${token}`;
  }

  return req;
});

ajax.interceptors.response.use(
  (resp) => Promise.resolve(resp),
  (error: AxiosError) => {
    const { request } = error;
    if (request?.status === 401) {
      store.dispatch(logout());
    }

    return Promise.reject(error);
  },
);
