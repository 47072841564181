import { useMediaQuery } from '@mui/material';
import { Breakpoint, useTheme } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';

export const useResponsive = (query: string, start: Breakpoint, end: Breakpoint) => {
  const theme = useTheme();

  const mediaUp = useMediaQuery(theme.breakpoints.up(start));

  const mediaDown = useMediaQuery(theme.breakpoints.down(start));

  const mediaBetween = useMediaQuery(theme.breakpoints.between(start, end));

  const mediaOnly = useMediaQuery(theme.breakpoints.only(start));

  if (query === 'up') {
    return mediaUp;
  }

  if (query === 'down') {
    return mediaDown;
  }

  if (query === 'between') {
    return mediaBetween;
  }

  return mediaOnly;
};

export const useIsMobile = () => {
  const theme = useTheme();

  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(!!smDown);
  }, [smDown]);

  return { isMobile };
};

export const useIsTablet = () => {
  const theme = useTheme();

  const mdDown = useMediaQuery(theme.breakpoints.down('md'));

  return { isTablet: !!mdDown };
};

export const useWidth = () => {
  const theme = useTheme();

  const keys = [...theme.breakpoints.keys].reverse();

  return (
    keys.reduce((output: Breakpoint | null, key: Breakpoint) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const matches = useMediaQuery(theme.breakpoints.up(key));

      return !output && matches ? key : output;
    }, null) || 'xs'
  );
};
