import { useQuery } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useEffect } from 'react';

import { ErrorsResponseType } from 'common/axios/type';
import { useAppDispatch, useAppSelector } from 'common/redux/store';
import { selectAuth, sessionCurrent } from 'modules/auth/redux/authReducer';

import { SESSION_URL } from '../requests/constants';
import { sessionCurrent as sessionCurrentRequest } from '../requests/session';
import { SessionCurrentResponseType } from '../requests/type';

export const useSessionQuery = () => {
  const dispatch = useAppDispatch();

  const { user, isAuth } = useAppSelector(selectAuth);

  const query = useQuery<
    AxiosResponse<SessionCurrentResponseType>,
    AxiosError<ErrorsResponseType>,
    SessionCurrentResponseType
  >([SESSION_URL.CURRENT], sessionCurrentRequest, {
    enabled: !isAuth,
    select: ({ data }) => data,
  });

  useEffect(() => {
    if (!user && query.data) {
      dispatch(sessionCurrent(query.data));
    }
  }, [dispatch, query, user]);

  return { ...query, data: user || query.data };
};
