import { useCallback } from 'react';
import { useLocation, matchPath } from 'react-router-dom';

export const useCheckSelectedPath = () => {
  const location = useLocation();

  const checkSelectedPath = useCallback(
    (path: string) => {
      const matchedPath = matchPath(`${path}/*`, location.pathname);

      return !!matchedPath;
    },
    [location],
  );

  return { checkSelectedPath };
};
