import { Box, styled } from '@mui/material';

import { navbarHeight, navbarHeightSm } from './components/Navbar/Navbar.styles';

export const privateContainerMaxWidth = 1440;

export const Main = styled('main')(({ theme }) => ({
  display: 'flex',
  flex: 'auto',
  justifyContent: 'center',

  flexGrow: 1,

  width: '100%',
  borderRadius: 0,

  padding: theme.spacing(3, 1.5, 0, 1.5),
}));

export const ContentBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  marginTop: '0px',
  paddingRight: theme.spacing(0),
  minHeight: `calc(100vh - ${navbarHeightSm}px)`,
}));

export const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: 'auto',
  justifyContent: 'center',
  minHeight: 'auto',
  maxWidth: privateContainerMaxWidth,
  marginLeft: 'auto',
  marginRight: 'auto',
  overflow: 'hidden',
  paddingTop: navbarHeight,

  [theme.breakpoints.down('md')]: {
    paddingTop: navbarHeightSm,
  },

  '& > div': {
    flexBasis: '100%',
  },
}));

export const Root = styled('div')(({ theme }) => ({
  minHeight: `calc(100vh - ${navbarHeightSm}px)`,
}));
