import { styled, CSSObject, SwipeableDrawer, Theme } from '@mui/material';

import { navbarHeight, navbarHeightSm } from '../../Navbar.styles';

export const drawerWidth = 210;
export const closedDrawerWidth = 70;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: closedDrawerWidth,
  [theme.breakpoints.up('sm')]: {
    width: closedDrawerWidth,
  },
});

export const Drawer = styled(SwipeableDrawer)(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  '& .MuiPaper-root': {
    marginTop: navbarHeight,
    borderRight: 0,
  },

  [theme.breakpoints.down('sm')]: {
    '& .MuiPaper-root': {
      marginTop: navbarHeightSm,
      width: closedDrawerWidth,
    },
  },

  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));
