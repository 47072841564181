import ExitToAppRoundedIcon from '@mui/icons-material/ExitToAppRounded';
import MenuIcon from '@mui/icons-material/Menu';
import { Box, IconButton, Typography, Hidden, useTheme } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';

import { FlexBox } from 'common/components/FlexBox';
import { Logo } from 'common/components/Logo';
import { useIsMobile } from 'common/hooks/useResponsive';
import { logout } from 'modules/auth/redux/authReducer';

import { AppBar, AppToolbar } from './Navbar.styles';

type Props = {
  toggleDrawer: () => void;
};

export const Navbar = ({ toggleDrawer }: Props) => {
  const { isMobile } = useIsMobile();
  const theme = useTheme();
  const dispatch = useDispatch();
  const handleLogoutClick = () => dispatch(logout());

  return (
    <AppBar elevation={0} color="inherit">
      <AppToolbar>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box mr={!isMobile ? 2 : 1}>
            <IconButton color="inherit" aria-label="open drawer" onClick={() => toggleDrawer()} edge="start">
              <MenuIcon />
            </IconButton>
          </Box>
          <Logo disabledLink />
          <Hidden smDown>
            <Typography variant="body1" fontWeight={600} color={theme.palette.text.primary} ml={theme.spacing(2)}>
              Pro Parser
            </Typography>
          </Hidden>
        </Box>
        <FlexBox>
          <IconButton onClick={handleLogoutClick} color="error" aria-label="">
            <ExitToAppRoundedIcon />
          </IconButton>
        </FlexBox>
      </AppToolbar>
    </AppBar>
  );
};
